(function () {
  'use strict';

  const selectors = {
    prices_button: '.price__button-element',
    prices_table: '.price__table-container',
    select_option: '.js-price__select-element'
  };

  const init = () => {
    const pricesButton = document.querySelectorAll(selectors.prices_button);
    // const pricesTable = document.querySelectorAll(selectors.prices_table);
    const selectOption = document.querySelector(selectors.select_option);

    if (selectOption instanceof HTMLElement) {
      selectOption.addEventListener('change', e => {
        const target = e.currentTarget;
        const container = target.closest('.price');
        const pricesButton = container.querySelectorAll(selectors.prices_button);
        const pricesTable = container.querySelectorAll(selectors.prices_table);

        for (const priceButtonSecond of pricesButton) {
          if (priceButtonSecond instanceof HTMLElement) {
            priceButtonSecond.classList.remove('price__button-element--active');
          }
        }

        container
          .querySelector(`[data-action="${e.currentTarget.value}"]`)
          .classList.add('price__button-element--active');

        for (const priceTable of pricesTable) {
          if (priceTable instanceof HTMLElement) {
            priceTable.classList.add('price__table-container--hidden');
          }
        }

        container
          .querySelector(`[data-entity="${e.currentTarget.value}"]`)
          .classList.remove('price__table-container--hidden');
      });
    }

    for (const priceButton of pricesButton) {
      if (priceButton instanceof HTMLElement) {
        priceButton.addEventListener('click', e => {
          const priceWrapper = e.currentTarget.dataset.action;
          const container = e.currentTarget.closest('.price');
          const pricesButton = container.querySelectorAll(selectors.prices_button);
          const pricesTable = container.querySelectorAll(selectors.prices_table);
          const selectOption = container.querySelector(selectors.select_option);

          for (const priceButtonSecond of pricesButton) {
            if (priceButtonSecond instanceof HTMLElement) {
              priceButtonSecond.classList.remove('price__button-element--active');
            }
          }

          e.currentTarget.classList.add('price__button-element--active');

          for (const priceTable of pricesTable) {
            if (priceTable instanceof HTMLElement) {
              priceTable.classList.add('price__table-container--hidden');
            }
          }

          selectOption.value = priceWrapper;

          container.querySelector(`[data-entity="${priceWrapper}"]`).classList.remove('price__table-container--hidden');
        });
      }
    }
  };

  window.addEventListener('load', init);
})();
