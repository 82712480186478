(() => {
  const selectors = {
    teaserElement: '.js-teaser' // Definierter Selektor für das Ziel-Element
  };

  const setDynamicMargin = () => {
    const element = document.querySelector(selectors.teaserElement); // Element mit der definierten Klasse auswählen
    if (element) {
      const elementHeight = element.offsetHeight; // Höhe des Elements in Pixeln abrufen
      element.style.marginBottom = `${elementHeight * 0.3}px`; // 30% der Höhe als Margin-Bottom setzen
    }
  };

  // Event Listener für DOMContentLoaded und Fenstergrößenänderung
  document.addEventListener('DOMContentLoaded', setDynamicMargin);
  window.addEventListener('resize', setDynamicMargin);
})();
