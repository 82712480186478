import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-list-members',
    cards: '.js-button-list-members'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    const cards = document.querySelectorAll(selectors.cards);
    splides.forEach(splide => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'loop',
          autoplay: false,
          drag: true,
          width: '100%',
          speed: 1000,
          perMove: 1,
          perPage: 3.5,
          gap: 24,
          destroy: false,
          breakpoints: {
            1025: {
              perPage: 2.5,
              drag: true,
              destroy: false
            },
            640: {
              perPage: 1.5,
              drag: true,
              destroy: false
            }
          },
          arrows: false,
          pagination: false,
          classes: {
            arrow: 'splide__arrow list-members-arrow',
            prev: 'splide__arrow--prev list-members-arrow--prev',
            next: 'splide__arrow--next list-members-arrow--next'
          }
        }).mount();
      }
    });

    for (const card of cards) {
      card.addEventListener('click', e => {
        card.classList.remove('list-members__card-wrapper');
        const currentId = e.currentTarget.dataset.number;
        const cardContainer = document.querySelector(`article[data-number="${currentId}"]`);
        if (cardContainer instanceof HTMLElement) {
          cardContainer.classList.contains('list-members__card-wrapper--active')
            ? cardContainer.classList.remove('list-members__card-wrapper--active')
            : cardContainer.classList.add('list-members__card-wrapper--active');
        }
      });
    }
  };

  window.addEventListener('load', init);
})();
