(() => {
  const selectors = {
    scrollTopButton: '.js-scroll-top'
  };

  const scrollTopButton = document.querySelector(selectors.scrollTopButton);

  // Button anzeigen/ausblenden, wenn der Benutzer scrollt
  window.addEventListener('scroll', () => {
    if (window.scrollY > 200) {
      scrollTopButton.classList.add('scroll-up--show');
    } else {
      scrollTopButton.classList.remove('scroll-up--show');
    }
  });

  // Scroll-to-Top mit Smooth Scrolling
  scrollTopButton.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
})();
