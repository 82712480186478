import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-gallery',
    images: '.gallery__element-image',
    lightbox: '.js-lightbox',
    toggleButton: '.js-toggle-lightbox',
    lightboxImage: '.js-lightbox__image',
    prevButton: '.js-lightbox__button--prev',
    nextButton: '.js-lightbox__button--next',
    nextArrowButton: '.js-gallery-button--next',
    previousArrowButton: '.js-gallery-button--previous'
  };

  const init = () => {
    const images = document.querySelectorAll(selectors.images);
    const lightbox = document.querySelector(selectors.lightbox);
    const lightboxImage = document.querySelector(selectors.lightboxImage);
    const prevButton = document.querySelector(selectors.prevButton);
    const nextButton = document.querySelector(selectors.nextButton);
    const splidesGallery = document.querySelectorAll(selectors.splide);
    const toggleButton = document.querySelector(selectors.toggleButton);
    let currentImageIndex;

    splidesGallery.forEach(splide => {
      if (splide instanceof HTMLElement) {
        const splider = new Splide(splide, {
          type: 'slide',
          autoplay: true,
          rewind: true,
          width: '100%',
          speed: 1000,
          perPage: 1,
          focus: 'center',
          gap: '1rem',
          updateOnMove: true,
          arrows: false,
          classes: {
            arrow: 'splide__arrow gallery-arrow',
            prev: 'splide__arrow--prev gallery-arrow-prev',
            next: 'splide__arrow--next gallery-arrow-next'
          },
          pagination: false
        }).mount();

        const nextArrowButton = splide.querySelector(selectors.nextArrowButton);
        const previousArrowButton = splide.querySelector(selectors.previousArrowButton);

        nextArrowButton.addEventListener('click', () => {
          splider.go('+1');
        });

        previousArrowButton.addEventListener('click', () => {
          splider.go('-1');
        });
      }
    });

    if (toggleButton instanceof HTMLElement) {
      toggleButton.addEventListener('click', () => {
        lightbox.style.display = 'flex';
        lightboxImage.src = images[0].src;
        currentImageIndex = 1;
      });
    }

    images.forEach((image, index) => {
      image.addEventListener('click', () => {
        lightbox.style.display = 'flex';
        lightboxImage.src = image.src;
        currentImageIndex = index;
      });
    });

    if (lightbox instanceof HTMLElement) {
      lightbox.addEventListener('click', e => {
        if (e.target !== lightboxImage && e.target !== prevButton && e.target !== nextButton) {
          lightbox.style.display = 'none';
        }
      });
    }

    if (prevButton instanceof HTMLElement) {
      prevButton.addEventListener('click', () => {
        currentImageIndex = (currentImageIndex - 1 + images.length) % images.length;
        lightboxImage.src = images[currentImageIndex].src;
      });
    }

    if (nextButton instanceof HTMLElement) {
      nextButton.addEventListener('click', () => {
        currentImageIndex = (currentImageIndex + 1) % images.length;
        lightboxImage.src = images[currentImageIndex].src;
      });
    }
  };

  window.addEventListener('load', init);
})();
