import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splides: '.js-stage-cards',
    cardText: '.js-cardtext'
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func.apply(this, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const applyMultilineEllipsis = e => {
    const originalText = e.dataset.originalText;
    const ellipsis = '...';

    e.innerText = originalText;

    const containerHeight = e.clientHeight;
    const computedStyle = window.getComputedStyle(e);
    let lineHeight = parseFloat(computedStyle.lineHeight);

    if (isNaN(lineHeight) || lineHeight === 0) {
      const fontSize = parseFloat(computedStyle.fontSize);
      if (!isNaN(fontSize) && fontSize > 0) {
        lineHeight = fontSize * 1.2;
      } else {
        console.warn('Cannot determine line-height for element:', e);
        return;
      }
    }

    const maxLines = Math.floor(containerHeight / lineHeight);

    if (maxLines < 1) {
      // e.innerText = originalText;
      return;
    }

    if (e.scrollHeight <= containerHeight) {
      return;
    }

    const words = originalText.split(' ');
    const length = words.length;
    let truncatedText = '';

    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const testText = truncatedText + words[i] + ' ';
        e.innerText = testText + ellipsis;

        if (e.scrollHeight > containerHeight) {
          break;
        } else {
          truncatedText = testText;
        }
      }

      e.innerText = truncatedText.trim() + ellipsis;
    }
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splides);
    const textElements = document.querySelectorAll(selectors.cardText);

    splides.forEach(splide => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'slide',
          autoplay: false,
          drag: true,
          speed: 1000,
          rewind: true,
          perPage: 3,
          perMove: 1,
          destroy: true,
          breakpoints: {
            480: {
              perPage: 1,
              perMove: 1,
              destroy: false,
              arrows: true
            },
            1025: {
              perPage: 2,
              drag: true,
              perMove: 1,
              destroy: false,
              arrows: false
            }
          },
          gap: 24,
          arrows: true,
          pagination: false,
          classes: {
            arrow: 'splide__arrow stage__card-arrow',
            prev: 'splide__arrow--prev stage__card-arrow-prev',
            next: 'splide__arrow--next stage__card-arrow-next',
            pagination: 'splide__pagination stage__card-pagination',
            page: 'splide__pagination__page stage__card-page'
          }
        }).mount();
      }
    });

    textElements.forEach(e => {
      if (e.dataset.originalText === undefined) {
        e.dataset.originalText = e.innerText;
      }
      applyMultilineEllipsis(e);
    });

    // Anwendung der Ellipsis bei Fenstergrößenänderungen mit Debounce
    window.addEventListener(
      'resize',
      debounce(() => {
        textElements.forEach(e => {
          e.innerText = e.dataset.originalText;
          applyMultilineEllipsis(e);
        });
      }, 200)
    );
  };

  window.addEventListener('load', init);
})();
