(function () {
  'use strict';

  const selectors = {
    burger: '.js-burger-trigger',
    closeButton: '.close__nav__button',
    navigationLinks: '.js-navigation-link',
    checkboxSelector: '#checkbox_toggle'
  };

  const init = () => {
    const burger = document.querySelector(selectors.burger);
    const closeButton = document.querySelector(selectors.closeButton);
    const navigationLinks = document.querySelectorAll(selectors.navigationLinks);
    const body = document.querySelector('body');

    if (burger instanceof HTMLElement) {
      burger.addEventListener('click', function () {
        const currentExpanded = burger.getAttribute('data-expanded');

        const newExpanded = currentExpanded === 'true' ? 'false' : 'true';

        currentExpanded !== 'true' ? body.classList.add('fixed') : body.classList.remove('fixed');

        burger.setAttribute('data-expanded', newExpanded);
      });
    }

    if (closeButton instanceof HTMLElement) {
      closeButton.addEventListener('click', function () {
        const checkboxToggle = document.querySelector(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    }

    navigationLinks.forEach(navigationLink => {
      navigationLink.addEventListener('click', function () {
        const checkboxToggle = document.querySelector(selectors.checkboxSelector);
        body.classList.remove('fixed');

        checkboxToggle.checked = false;
      });
    });
  };

  window.addEventListener('load', init);
})();
